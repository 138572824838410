
export const AService = (props)=>{
    let items = [];

    for(var i in props.items){
      items.push(<li key={i}>{props.items[i]}</li>);
    }
    return(
      <div>
        <h3>{props.title}</h3>
        <h6>{props.subtitle}</h6>
        <ul>{items}</ul>
      </div>
    )
  }
