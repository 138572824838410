
// id="pf-3dpe"
// tags={["Full Stack", "My Design"]}
// title="3dPE.org - Full-Stack Site for a Game&nbsp;Engine"
// link="https://3dpe.org/"
// link_text="Visit 3dPE.org"
// >
//   <div>
//     <p className="lead">An emphasis on creativity and robots.</p>

//     <p>In my spare time, I am programming a game engine in Python. The main goal is to provide simple tools that allow anyone to make small and weird games. The site is tailor-made to support the engine.</p>

//     <p>The backend is Laravel 6, with the site providing an API for user authentication and project management. It interfaces with the project's git repos to show engine progress, with GitLab to store creators' game projects, and with a Discord server via a node.js bot.</p>

//     <p>Each project is provided with a landing page, news announcement system, user management, and a full forum-style message-board.</p>

//     <p>The frontend is built using Bootstrap with customised SASS, CSS animations, embedded background video, and various other adjustments.</p>

//     <p>The design's main focus is to show a wide range of creativity for game creators, symbolised by broad rainbow colours on a neutral grey backdrop.</p>

//     <p>I created the various 3D models in Blender, and the mascot (3bot)'s retro, loose-(or no-)limbed style is meant to foster a sense of playfulness and fun imperfection.</p>
//   </div>
//   <div>
//     [gifs and screenshots]
//   </div>




export const Section = (props)=>{
    let tags = [];
    for(var t in props.tags){
      tags.push(
        <span key={t} className="inline-block mr-2 mb-2 bg-black bg-opacity-70 py-1 px-2 text-white text-sm font-display font-bold">{props.tags[t]}</span>
      )
    }

    return(
      // full screen height
      <div id={props.id} className="flex min-h-screen">
        {/* full width, auto height */}
        <div className={"my-auto w-full min-h-66 py-12 flex portfolio-bg "+props.className} style={{'backgroundImage':"url("+props.bg+")"}}>
          {/* nice-width container inside */}
          <div className="container m-auto p-8 main">
            <div>
              {tags}
            </div>

            <h2>{props.title}</h2>

            <div className="grid xl:grid-cols-2 gap-4">
              <div>
                {props.children[0]}

                <p>
                  <a className="btn" target="_blank" rel="noopener noreferrer nofollow" href={props.link}>
                    {props.link_text}
                  </a>
                </p>
              </div>

              <div>
                {props.children[1]}
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
