import React from 'react'

export const Lightbox = ({state_values, setStateValues}) => {
    function closeLightbox(){
      setStateValues(prevStateValues => ({
        ...prevStateValues,
        'show_lightbox':false
      }))
    }
    function nextLightbox(){
      let next = state_values.lightbox_set_index + 1;
      if (next >= state_values.lightbox_image_sets.length){
        next = 0;
      }
      setStateValues(prevStateValues => ({
        ...prevStateValues,
        'lightbox_set_index':next
      }))
    }
    function prevLightbox(){
      let prev = state_values.lightbox_set_index - 1;
      if (prev < 0){
        prev = state_values.lightbox_image_sets.length-1;
      }
      setStateValues(prevStateValues => ({
        ...prevStateValues,
        'lightbox_set_index':prev
      }))
    }

    let info = "";
    let images = [];
    let pos_dots = [];

    console.log(state_values.lightbox_image_sets[state_values.lightbox_set_index])

    if (state_values.lightbox_image_sets.length){
      console.log('data set')
      info = state_values.lightbox_image_sets[state_values.lightbox_set_index].info;

      console.log("imgs", state_values.lightbox_image_sets[state_values.lightbox_set_index].imgs.length)
      for(let i in state_values.lightbox_image_sets[state_values.lightbox_set_index].imgs){
        console.log("push img", i)
        images.push(
          <img
            key={i}
            alt={"Image "+(i+1)+" - "+state_values.lightbox_image_sets[state_values.lightbox_set_index].blurb}
            src={state_values.lightbox_image_sets[state_values.lightbox_set_index].imgs[i]}
            className="object-contain"
            // style={{'maxHeight':'100%'}}
          />
            // <div className="w-full h-full background-contain" style={{backgroundImage:"url("+state_values.lightbox_image_sets[state_values.lightbox_set_index].imgs[i]}}>
            //   &nbsp;
            // </div>
        )
      }
      console.log("mages", images)

      for(let i=0; i < state_values.lightbox_image_sets.length; i++){
        pos_dots.push(
          <div key={i} className={"inline-block w-10 v "+(i === state_values.lightbox_set_index ? 'text-lg' : 'text-xs')}>
            &#11044;
          </div>
        );
      }
    }

    let classes = "fixed inset-0 w-full h-full bg-black bg-opacity-70 text-white flex";
    if (!state_values.show_lightbox){
      classes += " hidden";
    }
    if (!state_values.fading_lightbox){
      classes += " opacity-100"
    } else {
      classes += " opacity-0";
    }

    return (
      <div id="lightbox" className={classes}>

        {/* image and text */}
        <div className="m-auto p-12  gap-12 flex flex-col overflow-y-auto h-full">
          <div className="m-auto flex-initial overflow-auto">
            <div  style={{maxHeight:"100%"}}>
              <div className={"h-full gap-4 grid grid-cols-"+images.length} style={{maxHeight:"100%"}}>
                {images.length ? images: ''}
              </div>
            </div>
          </div>
          <div className="m-auto">
            <div className="container">
              {info}
            </div>
          </div>
        </div>

        {/* controls */}

        <div className="absolute top-4 right-4 bottom-4 text-5xl cursor-pointer flex" title="Next" onClick={nextLightbox}>
          <div className="m-auto">&gt;</div>
        </div>
        <div className="absolute top-4 left-4 bottom-4 text-5xl cursor-pointer flex" title="Next" onClick={prevLightbox}>
          <div className="m-auto">&lt;</div>
        </div>

        <div className="absolute right-0 py-4 px-8 text-5xl cursor-pointer" title="Close Viewer" onClick={closeLightbox}>&times;</div>

        <div className="absolute bottom-4 left-4 right-4 text-center align-middle">
          {pos_dots}
        </div>


      </div>
    )
  }









  //props.prefix = assets/sections/{prefix}/image.png
  // props.children = list of <div img="possible,multiple"><p>with text here</p></div>

  /*
  first image is shown centered in the panel
  overflow:hidden, the second and last images are shown either side, smaller, offscreened

  when you hover teh *panel*, cursor:pointer, the main one should expand slghtly, and a short blurb excerpt should fade in at the bottom, w obvious ...

  when you click, lightbox mode, obvious X, maybe bind esc
  */
  export const ImgSet = (props)=>{
    let children = React.Children.toArray(props.children);
    let image_sets = [];
    for(let i in children){
      let img_set_div = children[i]

      let img_list = img_set_div.props.img.split(",");
      for (var j in img_list){
        img_list[j] = "assets/sections/"+props.prefix+"/"+img_list[j];
      }
      let paragraphs = React.Children.toArray(img_set_div.props.children);
      image_sets.push({
        'imgs': img_list,
        'info': img_set_div.props.children,
        'blurb': paragraphs[0].props.children
      })
    }
    let last = image_sets.length-1;


    function startLightbox(){
      props.setStateValues(prevStateValues => ({
        ...prevStateValues,
        show_lightbox:true,
        fading_lightbox:true,
        lightbox_image_sets: image_sets,
        lightbox_set_index: 0
      }));
      setTimeout(function(){
        props.setStateValues(prevStateValues => ({
          ...prevStateValues,
          fading_lightbox:false,
        }));
      }, 1);
    }

    return (
      <div className="gallery-preview relative overflow-hidden py-8" onClick={startLightbox}>

        <img
          className="left absolute max-w-66 max-h-66 m-auto"
          src={image_sets[last].imgs[image_sets[last].imgs.length-1]}
          alt={image_sets[last].blurb}
        />
        <img
          className="right absolute max-w-66 max-h-66 m-auto"
          src={image_sets[1].imgs[0]}
          alt={image_sets[1].blurb}
        />

        <img
          src={image_sets[0].imgs[0]}
          alt={image_sets[0].blurb}
          className="max-w-80 max-h-80 m-auto relative first"
        />

      </div>
    )
  }
