

//md and xl+ - col-start-2, aligned rightish, green border
//"xs", sm and lg - just 2 columns. span2+start1
export const InfoKey = (props)=>{
  return(
    <div className="
    col-span-2  col-start-1 border-r-0

    md:col-span-1 md:col-start-2 md:border-r
    lg:col-span-2 lg:col-start-1 lg:border-r-0
    xl:col-span-1 xl:col-start-2 xl:border-r

    px-r-5 py-1  border-lightgreen">{props.children}</div>
  )
}

export const InfoVal = (props)=>{
  return(
    <div className="col-span-2 px-5 py-1">{props.children}</div>
  )
}

export const InfoAndSkills = (props)=>{
  return (
  <div className={props.className}>
    <h3>Web Dev Skillset</h3>
    <div className="flex gap-4">
      <div className="flex-auto">
        <p>
            <strong>PHP</strong><br/>
            <strong>Laravel</strong><br/>
            <strong>MySQL</strong><br/>
            Inertia
        </p>

        <p>
            <strong>Git</strong>
        </p>


        <p>
            <strong>APIs and Apps</strong><br/>
            <strong>Shopify</strong><br/>
            Etsy<br/>
            Stripe<br/>
            Zoom
        </p>

        <p>
            <strong>Python</strong><br/>
            wxWidgets<br/>
            OpenGL 2<br/>
            GLSL 1.2<br/>
            Pygame
        </p>

        <p>
            <strong>iOS Apps</strong><br/>
            Cordova/Phonegap<br/>
            Enterprise Provisioning<br/>
        </p>
      </div>


      <div className="flex-auto">
        <p>
            <strong>JavaScript</strong><br/>
            <strong>Vue</strong><br/>
            React<br/>
            Polaris, Liquid<br/>
            Node<br/>
            jQuery<br/>
            Canvas, WebGL<br/>
            Websockets
        </p>

        <p>
            <strong>CSS</strong><br/>
            <strong>Tailwind</strong><br/>
            Sass, Less<br/>
            Bootstrap<br/>
            Foundation<br/>
            Animations
        </p>

        <p>
            <strong>Blender</strong><br/>
            Motion tracking<br/>
            Modelling, Texturing<br/>
            Lighting, Staging
        </p>

        <p>
            <strong>Photoshop</strong>
        </p>

        <p>
            <strong>Illustrator</strong>
        </p>
      </div>

    </div>
  </div>
  );
}
